@charset "utf-8"

@import 'npm:aos/dist/aos.css'

@tailwind base
@tailwind components
@tailwind utilities

$primary: #00D0A6
$text: #273456

@layer base
  a
    @apply transition-colors duration-300 ease-in-out
    color: $text

    &:hover
      @apply text-primary

    &.is-primary
      @apply text-primary

      &:hover
        @apply text-teal

  html
    color: $text

  .no-scrollbar::-webkit-scrollbar
    display: none
    
  .no-scrollbar
    -ms-overflow-style: none
    scrollbar-width: none

  ul.custom-marker
    li::before
      @apply inline-block w-3 h-3 rounded-full mr-2 bg-violet
      content: ""

  ul.green-marker
    li::marker
      color: #00D0A6

  ul.green-check-marker
    li
      list-style-type: '\2713'
      
      &::marker
        color: #1FBAB1

  form
    label.required:before
      color: $primary
      content: "* "

    & > .field:not(:last-child)
      margin-bottom: 1.875rem

    input::placeholder
      color: #bec2cc

  select
    &:invalid
      color: #bec2cc

  a.anchor
    display: block
    position: relative
    top: -150px
    visibility: hidden
  
  button
    font-size: 1rem

  button[data-carousel-slide-to]
    @apply bg-transparent border border-transparent

    &.green-control
      @apply bg-white border-0

      &[aria-current='true']
        @apply bg-primary
      
      &:hover
        @apply bg-primary

        &:not([aria-current='true'])
          @apply bg-primary

    &[aria-current='true']
      @apply bg-violet text-white

    &:hover
      @apply border-violet

      &:not([aria-current='true'])
        @apply bg-transparent

@layer utilities
  .bg-primary-gradiend
    background-image: linear-gradient(109deg, #00beb3, #00d0a6 61%, #00e58d)

  .short-dark-blue-background
    background-image: linear-gradient(#1f2c50 600px, #ffffff 600px)

  .select:not(.is-multiple):not(.is-loading)::after
    border-color: $text

  .bg-top-padding
    background-position: center top 78px, bottom
  
  .drop-shadow-offset
    box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.5)

@layer components
  .box
    @apply bg-white shadow-md rounded-lg p-8

  .button
    @apply cursor-pointer text-center whitespace-nowrap justify-center py-2 px-4 lg:px-8 inline-block rounded-2xl text-lg

    &.is-primary
      @apply bg-primary text-white border-transparent

      &:hover
        @apply bg-primary-dark text-white

  .navbar
    @apply w-full z-50 py-4 px-4 xl:px-20 transition-colors duration-300 ease-in-out bg-white shadow-md

    .navbar-item
      @apply hover:font-bold block px-2 transition-colors duration-300 ease-in-out text-inherit

      &.is-active
        color: $primary !important
      
      & > svg
        @apply scale-75 lg:scale-100
    
    .button.border-primary
      @apply text-primary
    
    &.fixed:not(.is-scrolled)
      @apply bg-transparent shadow-none

      .navbar-item
        @apply text-white

      #globe-icon, #logo-icon-name, button[data-drawer-target] > svg
        fill: #fff
      
      .button.border-primary
        @apply text-white

    #globe-icon, #logo-icon-name
      @apply transition-colors duration-300 ease-in-out

  .content
    ol
      @apply list-decimal
      margin-left: 1rem
    
    p
      @apply mb-4

    li
      p
        @apply mb-4

    ul
      li
        position: relative

        &::before
          content: "•"
          color: $primary
          position: absolute
          left: -1rem

  .title
    @apply text-2xl md:text-3xl font-semibold

  .hs-form-iframe
    min-height: 1550px !important

    @media (min-width: 375px)
      min-height: 1500px !important

    @media (min-width: 400px)
      min-height: 1400px !important

    @media (min-width: 480px)
      min-height: 1060px !important

    @media (min-width: 768px)
      min-height: 950px !important

  #product-slider
    .active
      @apply border-violet-lighter

  select:not([size]), .select-btn
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9.75758 9L18 1' stroke='%238C8C8C'/%3E%3C/svg%3E")
    background-position: right .75rem center
    background-repeat: no-repeat
    background-size: 1rem 1rem
    padding-right: 2.5rem !important
    min-height: 50px

  /* Ensure smooth transitions */
  .toggle-btn
    transition: color 0.2s ease

  .highlight
    transition: transform 0.2s ease, width 0.2s ease

  .pricing-cta-bg
    background: linear-gradient(180deg, rgba(255, 255, 255, 20%) 0%, rgba(43, 119, 162, 20%) 28%, rgba(77, 197, 196, 20%) 69%, rgba(255, 255, 255, 20%) 100%)

  .monthly.crossed
    @apply relative opacity-50 transition-opacity

    &:before
      @apply absolute left-0 right-0 border-2
      content: ""
      top: 50%
      border-color: $text
      transform: rotate(-15deg)

  .miller-box
    @apply bg-no-repeat bg-contain 2xl:bg-cover bg-bottom 2xl:bg-top

    @media (max-width: 1023px)
      @apply bg-[#273456]

    @media (min-width: 1024px)
      @apply bg-[url('./img/pricing/miller-bg.svg')]

  .iti
    display: block !important